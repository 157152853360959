import React, { createRef, useEffect } from "react"
import { HeaderLargeText, SubHeaderText } from "../elements"
import styled from "styled-components"
import animation from "../assets/animations/no-vacancies/toot-web-no-vacancies-t.json"
import lottie from "lottie-web"

const StyledEmptyState = styled.div`
  h3 {
    margin: auto;
  }
`

const EmptyState = () => {
  let animationContainer = createRef()

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledEmptyState>
      <HeaderLargeText>No vacancies!</HeaderLargeText>
      <SubHeaderText>We have no vacancies at the moment</SubHeaderText>
      <div className="animation-container" ref={animationContainer}></div>
    </StyledEmptyState>
  )
}

export default EmptyState
