import React, { createRef, useEffect } from "react"
import animation from "../assets/animations/join-us/toot-web-join-us-t.json"
import lottie from "lottie-web"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EmptyState from "./EmptyState"

const GridParentTemplate = props => {
  let animationContainer = createRef()
  const { title, content } = props.pageContext

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <SEO title={title} />
      {/* {content.Content[0].jobs.length >= 1 ? (
        <>
          <HeaderLargeText>{title}</HeaderLargeText>
          <SubHeaderText
            dangerouslySetInnerHTML={{ __html: description }}
          ></SubHeaderText>
          <DynamicZone components={content.Content} />
          <div className="animation-container" ref={animationContainer}></div>
        </>
      ) : ( */}
      <EmptyState />
      {/* )} */}
    </Layout>
  )
}

export default GridParentTemplate
